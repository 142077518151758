import SEO from "components/seo";
import React from "react";
import Layout from "components/layout";
import Manchester from "components/locationIndexPages/Manchester";

export default function index() {
  const title = "Manchester Translation Services Agency - Tomedes";
  const description =
    "If you're looking for high quality translation services, Manchester is the place to be. Tomedes' local, experienced translators are here to help.";
  const keywords = "Translation Services in Manchester";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/manchester-translation-services"
      />

      <Manchester />
    </Layout>
  );
}
